import React from 'react'
import {AppBar, Badge, IconButton, Toolbar, Typography, Menu} from '@material-ui/core';
import {
	ContactSupportOutlined,
	HomeOutlined,
	Instagram,
	LocalMallOutlined,
	MoreVert
} from "@material-ui/icons";

import {Link, useLocation} from 'react-router-dom';

import logo from '../../assets/logo.png';
import useStyles from './styles';

const Navbar = ({totalItems}) => {
	const classes = useStyles();
	const location = useLocation();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<AppBar position="fixed" className={classes.appBar} color="inherit">
				<Toolbar>
					<Typography component={Link} to="/" variant="h5" className={classes.title} color="inherit">
						<img src={logo} alt="OppositeDay logo" height="40px" className={classes.image}/>
						Opposite Day
					</Typography>
					<div className={classes.grow}/>

					{matchMedia('(max-width: 600px)').matches ?
						// mobile dropdown menu
						<div>
							<IconButton
								aria-label="more"
								id="long-button"
								aria-controls={open ? 'long-menu' : undefined}
								aria-expanded={open ? 'true' : undefined}
								aria-haspopup="true"
								onClick={handleClick}>
								<MoreVert />
							</IconButton>
							<Menu
								id="long-menu"
								MenuListProps={{
									'aria-labelledby': 'long-button',
								}}
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								PaperProps={{
									style: {
										marginTop: '45px',
										maxHeight: 180,
										width: '20ch',
										textAlign: 'center',
									},
								}}
							>
								<IconButton component={Link} to="/contact" aria-label="Contact Us" color="inherit" onClick={handleClose}>
									<Badge color="secondary">
										<ContactSupportOutlined />
									</Badge>
								</IconButton>
								<IconButton aria-label="Instagram" color="inherit" onClick={() => window.open('https://www.instagram.com/opposite_day_co', '_blank')}>
									<Badge color="secondary">
										<Instagram />
									</Badge>
								</IconButton>
								<IconButton component={Link} to="/" aria-label="Home" color="inherit" onClick={handleClose}>
									<HomeOutlined />
								</IconButton>
							</Menu>
						</div>
						:
						// desktop navbar
						<div>
							{location.pathname !== '/contact' && (
								<IconButton component={Link} to="/contact" aria-label="Contact Us" color="inherit">
									<Badge color="secondary">
										<ContactSupportOutlined />
									</Badge>
								</IconButton>
							)}
							<IconButton aria-label="Instagram" color="inherit" onClick={() => window.open('https://www.instagram.com/opposite_day_co', '_blank')}>
								<Badge color="secondary">
									<Instagram />
								</Badge>
							</IconButton>
						</div>
					}
					{location.pathname !== '/bag' && (
						<div className={classes.button}>
							<IconButton component={Link} to="/bag" aria-label="Show Cart Items" color="inherit">
								<Badge badgeContent={totalItems} color="secondary">
									<LocalMallOutlined/>
								</Badge>
							</IconButton>
						</div>)
					}
				</Toolbar>
			</AppBar>
		</>
	)
}

export default Navbar;
