import React from 'react';
import {Link} from "react-router-dom";
import "./style.css";
import "../../masterTemplate.css"
import useStyles from "../ContactUs/styles";
import {Badge, Button, CssBaseline, Divider, Grid, IconButton, Paper, Typography} from "@material-ui/core";
import {Instagram, MailOutlined} from "@material-ui/icons";

const ContactUs = () => {
	const classes = useStyles();

	return (
		<>
			<CssBaseline/>
			<main className={classes.layout}>
				<Paper className={classes.paper}>
					<div className={classes.bunchCentering}>
						<Typography variant="h5">Contact Us</Typography>
						<br/>
						<Typography variant="body1">Have any questions or issues?</Typography>
						<Typography variant="body1">Reach out via email or Instagram DM and we'll get back to you ASAP.</Typography>
					</div>
					<Divider className={classes.divider}/>
					<div className={classes.rowCentering}>
						<Grid style={{textAlign: 'center'}}>
							<IconButton href={`mailto:sales@oppositeday.co?Subject=Website Question`} aria-label="sales@oppositeday.co" color="inherit" >
								<Badge color="secondary">
									<MailOutlined />
								</Badge>
								<Typography variant="body1" className={classes.paddingLeft}>sales@oppositeday.co</Typography>
							</IconButton>
							<IconButton aria-label="Instagram" color="inherit" onClick={() => window.open('https://www.instagram.com/opposite_day_co', '_blank')}>
								<Badge color="secondary">
									<Instagram />
								</Badge>
								<Typography variant="body1" className={classes.paddingLeft}>@OppositeDayClothing</Typography>
							</IconButton>
						</Grid>
					</div>
					<Divider className={classes.divider} />
					<div className={classes.rowCentering}>
						<Button component={Link} variant="contained" color="secondary" type="button" to="/">Go Home</Button>
					</div>
				</Paper>
			</main>
		</>
	);
}

export default ContactUs;
