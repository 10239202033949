import React, {useEffect} from 'react'
import {Cart, ContactUs, Navbar, NotFound, Products} from './Components'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import ProductView from './Components/Products/ProductView/ProductView';
import Spinner from "./Components/Spinner/Spinner";
import {SnackbarProvider} from 'notistack';
import {initializeApp} from 'firebase/app'
import {getAnalytics, logEvent} from 'firebase/analytics'
import useStyles from './styles'
import productData from './assets/productDetails.json';

const theme = createTheme({
	palette: {
		primary: {
			light: '#f5fff0',
			main: '#c1ffbd',
			dark: '#8fcc8d',
			contrastText: '#000000',
		},
		secondary: {
			light: '#ffd0d4',
			main: '#ff9ea3',
			dark: '#ca6e74',
			contrastText: '#000000',
		},
		error: {
			light: 'red',
			main: 'red',
			dark: 'red',
			contrastText: '#000000',
		},
	},
	typography: {
		fontFamily: 'Signika Negative',
		fontWeightLight: 400,
		fontWeightRegular: 500,
		fontWeightMedium: 600,
		fontWeightBold: 700,
	}
});

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyD4xpcuO88Ol8E6QV9LI7iywQNYu-0xg_o",
	authDomain: "opposite-day.firebaseapp.com",
	projectId: "opposite-day",
	storageBucket: "opposite-day.appspot.com",
	messagingSenderId: "197540795751",
	appId: "1:197540795751:web:97e7e03091e55afac98bc2",
	measurementId: "G-JNS87VD7CP"
};

const App = () => {
	const classes = useStyles();

	// Initialize Firebase
	const app = initializeApp(firebaseConfig);
	const analytics = getAnalytics(app);

	const fetchProducts = async () => {
		logEvent(analytics, "homepage loaded")
	}

	useEffect(() => {
		fetchProducts().then();
		// eslint-disable-next-line
	}, []);

	if (!productData.products) return <Spinner/>

	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider
				maxSnack={3}
				classes={{
                    variantSuccess: classes.success,
                    variantError: classes.error,
					variantWarning: classes.warning,
					variantInfo: classes.info,
                }}>
				<Router>
					<div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', height: '100vh - 24px', width: '100vw - 24px'}}>
						<Navbar totalItems={0}/>
						<Switch>
							<Route exact path="/">
								<Products products={productData.products} />
							</Route>
							<Route exact path="/bag">
								<Cart />
							</Route>
							<Route exact path="/product/:permalink">
								<ProductView />
							</Route>
							<Route exact path="/contact">
								<ContactUs />
							</Route>
							<Route>
								<NotFound />
							</Route>
						</Switch>
					</div>
				</Router>
			</SnackbarProvider>
		</ThemeProvider>
	)
}
export default App;
