import React from 'react'
import {Button, Container, Grid, Typography} from '@material-ui/core';
import useStyles from './styles'
import {Link} from 'react-router-dom';
import {withSnackbar} from "notistack";

import "./style.css";
import "../../masterTemplate.css"

const Cart = () => {
	const classes = useStyles();

	const EmptyCart = () => (
		<Grid container style={{display: "flex", justifyContent: "center", margin: "auto"}}>
			<Grid>
				<Typography align='center' variant="subtitle1">You have no items in your Bag.</Typography>
				<Typography align='center' variant="subtitle1">Go back to the Home Page to add some!</Typography>
				<br/><br/>
				<Button style={{display: "flex", justifyContent: "center"}} component={Link} to="/"
				        className={classes.checkoutButton} size="large"
				        variant="contained" color="primary" type="button">Home</Button>
			</Grid>
		</Grid>
	);

	return (
		<Container>
			<div className={classes.toolbar}/>
			<br/>
			<EmptyCart/>
		</Container>
	)
}

export default withSnackbar(Cart)
